// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";

const application = Application.start();

application.debug = false;
window.Stimulus = application;

import ApplicationController from "./application_controller";
application.register("application", ApplicationController);

import AppearanceController from "./appearance_controller";
application.register("appearance", AppearanceController);

import Clipboard from "./clipboard_controller";
application.register("clipboard", Clipboard);

import CommandPaletteController from "./nav/command_palette_controller";
application.register("nav--command-palette", CommandPaletteController);

import Dropdown from "./dropdown_controller";
application.register("dropdown", Dropdown);

import FlashController from "./flash_controller";
application.register("flash", FlashController);

import TurboFlashController from "./turbo_flash_controller";
application.register("turbo-flash", TurboFlashController);

import AutoSubmit from "./auto_submit_controller";
application.register("auto-submit", AutoSubmit);

import FormController from "./form_controller";
application.register("form", FormController);

import Marketing__HomeController from "./marketing/home_controller";
application.register("marketing--home", Marketing__HomeController);

import MarqueeController from "./marquee_controller";
application.register("marquee", MarqueeController);

import NavController from "./nav_controller";
application.register("nav", NavController);

import NewModalController from "./new_modal_controller";
application.register("new-modal", NewModalController);

import FrequencySelectorController from "./billing/frequency_selector_controller";
application.register(
  "billing--frequency-selector",
  FrequencySelectorController
);

import SlideOverController from "./slide_over_controller";
application.register("slide-over", SlideOverController);
