import { Controller } from "@hotwired/stimulus";
import { Marquee, loop } from "dynamic-marquee";

export default class extends Controller {
  static values = {
    kind: String,
    definition: Array,
  };

  connect() {
    if (window.getComputedStyle(this.element).display == "none") return;

    this.marquee = new Marquee(this.element, { rate: 60, startOnScreen: true });

    loop(
      this.marquee,
      this.generateElements(this.kindValue, this.definitionValue),
      () => {
        const separator = document.createElement("div");
        separator.className = "w-12";
        return separator;
      }
    );
  }

  generateElements(kind, definition) {
    switch (kind) {
      case "logos":
        return this.generateLogoElements(definition);
    }
  }

  generateLogoElements(fromDefinition) {
    return fromDefinition.map((def) => {
      return () => {
        const image = document.createElement("img");
        image.className =
          "mx-auto h-10 aspect-[25/8] brightness-0 saturate-0 invert";
        image.src = def.src;
        image.alt = def.alt;

        return image;
      };
    });
  }
}
